/**
 * The router of the application.
 * Determines the paths and properties of the different views and how they are related to each other.
 **/
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/product-list',
        name: 'ProductList',
        component: () => import('@/views/products/ProductList.vue')
    },
    {
        path: '/product-list/:productId',
        name: 'Product',
        component: () => import('@/views/products/Product.vue'),
        props: true,
        children: [
            {
                path: '/product-list/:productId/cockpit',
                name: 'ProductCockpit',
                component: () => import('@/views/products/product-portfolio/ProductCockpit.vue'),
                props: true
            },
            {
                path: '/product-list/:productId/info',
                name: 'ProductInformation',
                component: () => import('@/views/products/product-portfolio/ProductInformation.vue'),
                props: true
            },
            {
                path: '/product-list/:productId/contents',
                name: 'ProductContents',
                component: () => import('@/views/products/product-portfolio/ProductContents.vue'),
                props: true
            },
            {
                path: '/product-list/:productId/schedule',
                name: 'ProductSchedule',
                component: () => import('@/views/products/product-portfolio/ProductSchedule.vue'),
                props: true
            },
            {
                path: '/product-list/:productId/assetOrders',
                name: 'ProductAssetOrders',
                component: () => import('@/views/products/product-portfolio/ProductAssetOrders.vue'),
                props: true
            },
            {
                path: '/product-list/:productId/processHistory',
                name: 'ProductProcessHistory',
                component: () => import('@/views/products/product-portfolio/ProductProcessHistory.vue'),
                props: true
            }
        ]
    },
    {
        path: '/task-list',
        name: 'TasksListOverview',
        component: () => import('@/views/tasks/TasksListOverview.vue'),
        children: [
            {
                path: '/task-list/visible',
                name: 'TaskList',
                component: () => import('@/views/tasks/TaskList.vue')
            },
            {
                path: '/task-list/hidden',
                name: 'HiddenTasksList',
                component: () => import('@/views/tasks/HiddenTasksList.vue')
            }
        ]
    },
    {
        path: '/task-list/:taskId',
        name: 'Task',
        component: () => import('@/views/tasks/Task.vue'),
        props: true
    },
    {
        path: '/administration/task-overview',
        name: 'AdminTaskOverview',
        component: () => import('@/views/administration/AdminTaskOverview.vue'),
        children: [
            {
                path: '/administration/task-overview/current-tasks',
                name: 'CurrentTasks',
                component: () => import('@/views/administration/admin-task-views/CurrentTasks.vue')
            },
            {
                path: '/administration/task-overview/failed-jobs',
                name: 'FailedJobs',
                component: () => import('@/views/administration/admin-task-views/FailedJobs.vue')
            }
        ]
    },
    {
        path: '/administration/controlling',
        name: 'Controlling',
        component: () => import('@/views/administration/Controlling.vue'),
        children: [
            {
                path: '/administration/controlling/process-models',
                name: 'ProcessModels',
                component: () => import('@/views/administration/controlling-views/ProcessModels.vue')
            },
            {
                path: '/administration/controlling/process-migrations',
                name: 'MigrationsOverview',
                component: () => import('@/views/administration/controlling-views/MigrationsOverview.vue')
            },
            {
                path: '/administration/controlling/product-completion',
                name: 'ProductCompletion',
                component: () => import('@/views/administration/controlling-views/ProductCompletion.vue')
            },
            {
                path: '/administration/controlling/conflicts',
                name: 'ProductConflicts',
                component: () => import('@/views/administration/controlling-views/ProductConflicts.vue')
            },
            {
                path: '/administration/controlling/operation',
                name: 'Operation',
                component: () => import('@/views/administration/controlling-views/Operation.vue')
            },
            {
                path: '/administration/controlling/test-user',
                name: 'CreateTestUser',
                component: () => import('@/views/administration/controlling-views/CreateTestUser.vue')
            }
        ]
    },
    {
        path: '/administration/controlling/process-migration/:processId',
        name: 'ProcessMigration',
        component: () => import('@/views/administration/controlling-views/ProcessMigration.vue'),
        props: true
    },
    {
        path: '/administration/playground',
        name: 'Playground',
        component: () => import('@/views/administration/Playground.vue')
    },
    {
        path: '/administration/gallery',
        name: 'Gallery',
        component: () => import('@/views/administration/Gallery.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/product-list'
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

// HACK this is a workaround: when using createWebHashHistory, the "to" route needs to be rewritten
// see https://stackoverflow.com/questions/73903119/keycloak-js-appending-session-data-to-url-in-vue3 and
//     https://github.com/gnieser/vue-keycloak/blob/master/src/router/index.js
router.beforeEach((to) => {
    if (to.path.includes('&state')) {
        return {
            path: to.path.substring(0, to.path.indexOf('&state')) // remove the query params which keycloak appended
        }
    }
})

export default router
