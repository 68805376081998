<script>
/**
 * Checkbox is a pre styled input type checkbox element.
 * It will throw an @input-change event on toggling the switch.
 * - id: Should be provided in order to get full functionality.
 * - label (optional): Text displayed as the label of the input field.
 * - defaultValue (optional): The default value of the input. For editing purposes for example.
 * - isDisabled (default: false): If true, the user can't change the input value.
 **/

export default {
    name: 'Checkbox',
    // @input-change: Event emitted on input | returns current input value
    emits: ['input-change'],
    props: {
        id: String,
        label: String,
        defaultValue: Boolean,
        isDisabled: {
            default: false,
            type: Boolean
        },
        notifyMore: {
            default: false,
            type: Boolean
        }
    },
    data () {
        return {
            inputValue: this.defaultValue || false
        }
    },
    methods: {
        emitInputChange (value) {
            this.$emit('input-change', {
                value
            })
        }
    },
    watch: {
        defaultValue () {
            this.inputValue = this.defaultValue || false
        },

        inputValue () {
            this.emitInputChange(this.inputValue)
        }
    },
    beforeMount () {
        // must be guarded, otherwise it will show dozens of update messages for all checkboxes in the user profile settings right after loading CorFlow
        if (this.notifyMore) {
            this.emitInputChange(this.inputValue)
        }
    },
    beforeUnmount () {
        // you may want to clear input value in form data when, say, moving back to another form section
        if (this.notifyMore) {
            this.emitInputChange(null)
        }
    }
}
</script>

<template>
    <div class="c_checkbox-wrapper generals-input-wrapper">
        <label v-if="label" class="c_checkbox-label generals-input-label">
            <span>{{label}}</span>
        </label>
        <div class="c_checkbox-container generals-input-container">
            <div class="c_checkbox generals-checkbox"
                 v-bind:class="{'m--disabled': isDisabled}">
                <label>
                    <input v-bind:id="id ? `c_checkbox_${id}`: null"
                           type="checkbox"
                           v-bind:disabled="isDisabled"
                           v-model="inputValue"/>
                    <span></span>
                </label>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_checkbox-wrapper {

    .c_checkbox-label {
    }

    .generals-input-container.c_checkbox-container {
        padding-top: 12px;

        .c_checkbox {
        }
    }
}
</style>
