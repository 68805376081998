/**
 * A reusable service for handling regex.
 * Test your regular expression in your browser console rather than some website
 **/

/**
 * Valid URL.
 * - 1)
 *    - a) starts with 'http://localhost:' and a port
 *    - or b)
 *      - 1. starts with 'http://' or 'https://' -> ^https?:\/\/
 *      - 2. followed by one or more subdomains (composed of letters, numbers, hyphen, ...) -> (?:[A-Za-z\d_-]+\.)+
 *      - 3. followed by top-level domain -> [a-zA-Z]{2,12}
 * - 2) end of domain part (path, start of parameters, start of fragment, or end of URL),
 *        everything after that is optional and will not be checked -> (?:$|#.*$|\?.*$|\/.*$)
 */
const urlPattern =
    /^(?:http:\/\/localhost:\d\d\d\d\d?|https?:\/\/(?:[A-Za-z\d_-]+\.)+[a-zA-Z]{2,12})(?:$|#.*$|\?.*$|\/.*$)/

/**
 * Valid email address.
 *
 *  - 1a. starts with one or more characters that are not special characters -> [^<>()[\]\\.,;:\s@#"]+
 *  - 2a. followed by either a '.' and a variety of non-special characters like in number 1 -> (\.[^<>()[\]\\.,;:\s@#"]+)*)
 *    - rule number 2 can be chained/repeated
 *  - 1b / 2b. Alternatively, anything you want in double quotes -> (".+")
 *  - 3. followed by an '@' -> @
 *  - 4. followed by a string, consisting of one or more letters, '-' or numbers, ending with a '.' -> ([a-zA-Z\-\d]+\.)+
 *    - rule number 4 can be chained/repeated
 *  - 5. followed by at least two letters -> [a-zA-Z]{2,}
 *  - 6. expect the end of the string -> $
 */
const emailPattern = /^(([^<>()[\]\\.,;:\s@#"]+(\.[^<>()[\]\\.,;:\s@#"]+)*)|(".+"))@([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}$/

/**
 * GEMA number.
 *
 * 6 to 8 digits, followed by a '-', followed by 3 digits
 */
const gemaNumberPattern = /^$|^\d{6,8}-\d{3}$/

export const regexHelper = {
    methods: {
        getMethodForPatternKey (patternKey: string): ((input: string) => boolean) {
            switch (patternKey) {
            case 'email':
                return this.isValidEmail
            case 'url':
                return this.isValidUrl
            case 'gemaNumber':
                return this.isValidGemaNumber
            }
            throw new Error('unknown pattern key: ' + patternKey)
        },

        isValidUrl (urlString: string): boolean {
            return urlPattern.test(urlString)
        },

        isValidEmail (emailString: string): boolean {
            return emailPattern.test(emailString.toLowerCase())
        },

        isValidGemaNumber (gemaNumberString: string): boolean {
            return gemaNumberPattern.test(gemaNumberString)
        },

        checkForPatternKey (patternKey: string, stringToCheck: string): boolean {
            return this.getMethodForPatternKey(patternKey)(stringToCheck)
        }
    }
}
